@import '../abstracts/mixins';

/**
 * global.scss
 * Contains the sitewide global styles
 */

* {
    background-repeat: no-repeat;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: relative;
    text-rendering: optimizeLegibility;

    &:before,
    &:after {
        box-sizing: border-box;
    }

    &:focus {
        outline: 1px dotted #000;
    }
}

html,
body {
    margin: 0;
    min-height: 100vh;
}

body {
    width: 100%;
}

img {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
li,
p,
input,
textarea {
    font-family: inherit;
}

.clearfix {
    zoom: 1;

    &:after {
        clear: both;
    }
    
    &:after,
    &:before {
        content: '\0020';
        display: block;
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
