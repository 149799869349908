/**
 * mixins.scss
 * Contains the sitewide mixins
 */

@mixin from-breakpoint($bp) {
    @if ($bp == 'x-small') {
        @content;
    }
    @else {
        @if map-has-key($breakpoints, $bp) {
            $list: map-get($breakpoints, $bp);
            $bp: nth($list, 1);
        }
        
        @media screen and (min-width: #{$bp}) {
            @content;
        }
    }
}

@mixin font($font-size, $line-height, $font-weight:400, $letter-spacing: 0) {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    letter-spacing: $letter-spacing;
}

@mixin host-block() {
    :host {
        display: block;
    }
}

@mixin float-left() {
    float: left;
}

@mixin float-right() {
    float: right;
}
