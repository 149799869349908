/**
 * variables.scss
 * Contains the sitewide variables
 */

/**
 * Breakpoints variables
 */

$breakpoints: (
    x-small: 0,
    mobile: 320px,
    tablet: 768px,
    desktop: 1024px
);

$orientations: (
    portrait: portrait,
    landscape: landscape
);

/**
 * Colors variables
 */

$colors: (
    'transparent': transparent,
);

/**
 * z-index variables
 */

$zindex: (
    'base': 1,
    'menu': 2,
    'overlay': 3,
    'modal': 4,
    'toast': 5
);
