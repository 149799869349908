@import './assets/style/base';
@import './assets/style/base/font.scss';

@font-face {
    font-family: Goudy-Regular;
    src: url('./assets/fonts/goudy_old_style_regular-webfont.woff') format('woff');
    font-weight: 400;
}

/* MODAL STYLES
    -------------------------------
*/
body dtr-hub-modal {
    /* modals are hidden by default */
    display: none;

    .modal {
        /* modal container fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* z-index must be higher than .modal-background */
        z-index: 1000;

        /* enables scrolling for tall modals */
        overflow: auto;

        .modal-body {
            background: #FFF;
            box-shadow: 0 1px 5px 1px rgba(102, 102, 86, 0.5);

            /* margin exposes part of the modal background */
        }
    }

    .modal-background {
        /* modal background fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* semi-transparent black  */
        background-color: #FFF;
        opacity: 0.75;

        /* z-index must be below .modal and above everything else  */
        z-index: 900;
    }
}

body.modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}

wdpr-single-select, wdpr-single-select *,
wdpr-input, wdpr-input *,
wdpr-messages, wdpr-messages *,
.single-select-box, .single-select-box *,
.wdpr-loading-section, .wdpr-loading-section * {
    font-family: $inspiretwdc-roman-font !important;
}

wdpr-single-select {
    --wdpr-single-select-trigger-button-font-family: #{$inspiretwdc-roman-font} !important;
}

wdpr-messages .wrapper .message-container span.message,
wdpr-messages, wdpr-messages * {
    --font-base_-_font-family: #{$inspiretwdc-font} !important;
}
wdpr-button, wdpr-button * {
    --button-font-family: #{$inspiretwdc-heavy-font};
}