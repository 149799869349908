/**
 * reset.scss
 * Contains the sitewide css reset
 */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    background: colors('transparent');
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
img,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;

    :before,
    :after {
        content: none;
    }
}

a {
    background: colors('transparent');
    font-size: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    border: 0;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}

address {
    font-style: normal;
}

input {
    &::-ms-clear {
        display: none;
    }
}

button {
    background: none;
    border: 0;
    padding: 0;
}
